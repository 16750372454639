export enum Variant {
  Primary = "primary",
  Secondary = "secondary",
}

export enum Type {
  Link = "a",
  Text = "span",
  Button = "button",
}
