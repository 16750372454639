import { SizeProps } from "./";

function buildAspectRatioProps(width: number, height: number) {
  return {
    width,
    height,
    sizes: "100vw",
  };
}

function buildGridRenderProps() {
  return {
    fill: true,
    sizes: "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw",
  };
}

export function getNextImageSizeProps({
  width,
  height,
  sizes,
  aspectRatio,
  grid,
}: SizeProps) {
  if (aspectRatio) {
    return buildAspectRatioProps(width!, height!);
  }

  if (grid) {
    return buildGridRenderProps();
  }

  return {
    sizes: sizes || "100vw",
    fill: true,
  };
}
