"use client";

import { EndemicHeader } from "./(components)/Basic/EndemicHeader";
import { ContentWrapper } from "./(components)/Wrappers/ContentWrapper";
import { Button } from "./(components)/Basic/Button";

import ErrorHeader from "@/assets/graphics/themed/endemic/not-found-header.svg";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";

export default function Error() {
  return (
    <ContentWrapper>
      <EndemicHeader
        Background={ErrorHeader}
        graphicName="error-header"
        isCentered={true}
        className="mb-4"
      >
        Error
      </EndemicHeader>
      <div className="flex flex-col items-center text-center mb-40">
        <h2 className="text-h1-sm lg:text-h1 mb-6">Oops!</h2>
        <h3 className="text-body-2 lg:text-body-1 mb-10">
          Something went wrong. Our developers are currently working on it.
        </h3>
        <Button endIcon={<ArrowRightIcon className="w-4" />} href="/">
          Return to homepage
        </Button>
      </div>
    </ContentWrapper>
  );
}
