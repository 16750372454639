import { endemic_museo } from "@/app/fonts";
import React from "react";
import { twMerge } from "tailwind-merge";
import Graphic from "@/app/(components)/Basic/Graphic";

export interface Props {
  graphicName: any;
  mobileGraphicName?: any;
  Background: React.ComponentType<any>;
  MobileBackground?: React.ComponentType<any>;
  isCentered?: boolean;
  children?: React.ReactNode;
  className?: string;
  imageWrapperClass?: string;
  graphicClass?: string;
}

const EndemicHeader = ({
  graphicName,
  isCentered = true,
  children,
  className,
  imageWrapperClass,
  graphicClass,
}: Props) => {
  return (
    <section
      className={twMerge(
        endemic_museo.variable,
        "lg:mb-20 relative md:px-0",
        className,
      )}
    >
      <div className={imageWrapperClass}>
        <Graphic
          graphicName={graphicName}
          className={twMerge(`max-w-full`, graphicClass)}
        />
        <h1
          className={twMerge(
            "font-endemic leading-none text-white text-[10vw] xl:text-[144px] absolute top-0 right-0 bottom-0 left-0 drop-shadow-endemic",
            isCentered ? "flex items-center justify-center" : "",
          )}
        >
          {children}
        </h1>
      </div>
    </section>
  );
};

export default EndemicHeader;
