import { twMerge } from "tailwind-merge";
import React from "react";

export interface Props {
  children: React.ReactNode;
  className?: string;
  removePaddingOnMobile?: boolean;
  removePadding?: boolean;
  fullWidth?: boolean;
  pageWrapper?: boolean;
}

function ContentWrapper({
  children,
  className,
  removePaddingOnMobile = false,
  removePadding = false,
  fullWidth = false,
  pageWrapper = false,
}: Props) {
  return (
    <div
      className={twMerge(
        "w-full mx-auto",
        className,
        removePadding
          ? ""
          : removePaddingOnMobile
            ? "lg:px-5"
            : "px-5 lg:px-12",
        fullWidth ? "" : "max-w-7xl ",
        pageWrapper && "mt-16 lg:mt-0",
      )}
    >
      {children}
    </div>
  );
}

export default ContentWrapper;
