"use client";
import { twMerge } from "tailwind-merge";
import React, {
  MouseEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Variant, Type } from "./constants";
import { hasTextContent } from "@/shared/utilities";
import Link from "next/link";

export interface Props {
  disabled?: boolean;
  element?: Type;
  variant?: Variant;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  href?: string;
  className?: string;
  children: React.ReactNode;
  native?: boolean;
  disableClick?: boolean;
  onClick?: (
    e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
  ) => void;
  type?: string;
  target?: string;
  startIconClassName?: string;
  disabledClassName?: string;
}

const Button = (
  {
    element = Type.Button,
    variant = Variant.Primary,
    native = false,
    fullWidth,
    startIcon,
    endIcon,
    disabled,
    className,
    children,
    onClick,
    disableClick,
    href,
    target,
    startIconClassName,
    disabledClassName,
    ...props
  }: Props,
  ref: any,
) => {
  const hasPadding = useMemo(() => hasTextContent(children), [children]);
  const [defaultElementType, setDefaultElementType] = useState(element);

  useEffect(() => {
    if (href) {
      setDefaultElementType(Type.Link);
    }
  }, [href]);

  const elementClasses = twMerge(
    !native &&
      "select-none appearance-none inline-flex items-center no-underline border-2 text-[18px] leading-[22px] rounded-full shadow-[0_4px_0_0_transparent] shadow-primary endemic:shadow-invert bg-primary text-primary endemic:text-invert border-primary endemic:border-invert px-4 py-1.5 transition-all -translate-y-1",
    !disabled && [
      `cursor-pointer active:!shadow-transparent active:!translate-y-0`,
    ],
    !native &&
      !disabled &&
      variant === Variant.Primary && [
        "active:bg-yellow md:hover:bg-yellow md:night:hover:bg-red",
      ],
    !native &&
      !disabled &&
      variant === Variant.Secondary && [
        `active:bg-secondary md:hover:bg-secondary`,
      ],
    fullWidth && "w-full",
    className,
    disabled &&
      `!text-disabled shadow-secondary border-secondary endemic:shadow-secondary endemic:border-secondary cursor-default pointer-events-none ${disabledClassName}`,
  );

  const generateButtonsBody = () => {
    return (
      <>
        {startIcon ? (
          <span
            className={twMerge(
              "fill-primary endemic:fill-invert",
              hasPadding ? "pr-2" : "",
              disabled &&
                "fill-secondary endemic:fill-disabled [&>svg]:fill-disabled",
              startIconClassName,
            )}
          >
            {startIcon}
          </span>
        ) : null}
        {children}
        {endIcon ? (
          <span
            className={twMerge(
              "ml-auto fill-primary endemic:fill-invert",
              hasPadding ? "pl-2" : "",
              disabled &&
                "fill-secondary endemic:fill-disabled [&>svg]:fill-disabled",
            )}
          >
            {endIcon}
          </span>
        ) : null}
      </>
    );
  };

  if (defaultElementType == Type.Link) {
    return (
      <Link
        ref={ref}
        className={elementClasses}
        href={!disabled ? href || {} : {}}
        target={target}
        onClick={(e: MouseEvent<HTMLAnchorElement>) =>
          !disabled && !disableClick && onClick?.(e)
        }
        {...props}
      >
        {generateButtonsBody()}
      </Link>
    );
  } else {
    return React.createElement(
      defaultElementType,
      {
        disabled,
        ref,
        className: elementClasses,
        ...props,
        onClick: (e: MouseEvent<HTMLButtonElement>) =>
          !disabled && !disableClick && onClick?.(e),
      },
      generateButtonsBody(),
    );
  }
};

export default forwardRef(Button);
